<template>
  <div class="projectData">
    <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="日期：">
        <data-time @dateChange="dateClick" />
      </el-form-item>
      <el-form-item label="所在地区：">
        <cascader-area
          :country="queryInfo.projectCountry"
          :province="queryInfo.projectProvince"
          :city="queryInfo.projectCity"
          @searchCountry="searchCountry"
          @searchProvince="searchProvince"
          @searchCity="searchCity"
        />
      </el-form-item>
      <el-form-item label="项目阶段：">
        <dic-radio-button
          :code.sync="queryInfo.projectStage"
          type-code="012"
          query-name="projectStage"
          name="项目阶段"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="行业类别：">
        <dic-tag-button
          :code.sync="queryInfo.reportTagsName"
          query-name="reportTagsName"
          name="行业类别"
          tag="行业"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="领域类别：">
        <dic-tag-button
          :code.sync="queryInfo.fieldIdName"
          query-name="fieldIdName"
          name="领域类别"
          tag="领域"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item,index) in tags"
          :key="index+item.name"
          @close="closeTag(index,item.tagName)"
        >
          {{item.name}}：{{queryInfo[item.tagName]?queryInfo[item.tagName]:'不限'}}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength')&&item.valLength>0"
          >+{{item.valLength}}</span>
        </el-tag>
      </el-form-item>
    </el-form>

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem">添加</el-button>
          <import-data moduleName="项目数据" @fileClick="fileClick" />
          <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" />
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color:#606266">按上传时间</div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="请输入项目名称、项目简介"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{prop: 'date', order: 'descending'}"
        @cell-dblclick="tableDoubleClick"
        @sort-change="sortChange"
        :cell-style="set_cell_style"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset />
        </template>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span>{{(queryInfo.pageNum-1)*queryInfo.pageSize+(scope.$index + 1)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="projectDataIntegrity"
          label="数据完整度"
          sortable="custom"
          align="center"
          width="120"
        >
          <template v-slot="{row}">
            <div @click="editItem(row)" style="cursor:pointer;">
              <el-progress type="circle" :percentage="row.projectDataIntegrity" :stroke-width="12"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center" width="230">
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor:pointer;">
              <span>{{row.projectName}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="projectCompany" label="需求方" align="center" />
        <el-table-column label="项目城市" align="center">
          <template v-slot="{row}">
            {{row.projectCountry?row.projectCountry:''}}
            {{row.projectProvince?'-'+row.projectProvince:''}}
            {{row.projectCity?'-'+row.projectCity:''}}
          </template>
        </el-table-column>
        <el-table-column prop="projectStage" label="项目阶段" align="center" width="100" />
        <el-table-column prop="projectCreatetime" label="开始(招标)时间" align="center" />
        <!-- <el-table-column prop="projectIndustryType" label="行业类别" align="center" width="220">
          <template v-slot="{row}">
            <span class="tableColor">{{row.projectIndustryType}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="projectFieldType" label="领域类别" align="center" width="220">
          <!-- <template v-slot="{row}">
            <span class="tableColor">{{row.projectFieldType}}</span>
          </template> -->
          <template slot-scope="scope">
            <div v-if="(scope.row.projectFieldType !== null) && (scope.row.projectFieldType !== '')" class="tableColor">
                  {{scope.row.projectFieldType.substring(0,20)}}
                  <span v-if="scope.row.projectFieldType.length>20">...</span>
                </div>
          </template>
        </el-table-column>
        <el-table-column prop="adminName" label="创建人" align="center" width="80"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center" width="120" :formatter="formatTime"></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{row}">
            <button-table @buttonClick="editItem(row)" content="编辑" icon="jr-iconjr-icon-edit" />
            <button-table @buttonClick="deleteItem(row)" content="删除" icon="jr-iconjr-icon-delect" />
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        :title="dialogType"
        :visible.sync="dialogVisible"
        width="60%"
        @close="handleClose"
        center
      >
        <el-form :model="editTableObj" label-width="100px">
          <el-form-item label="行业类别：" v-if="dialogType==='行业类别'">
            <dic-checkbox-group
              ref="checkboxProject"
              :code.sync="editTableObj.projectIndustryType"
              tag="行业"
              key="行业"
              @updateCode="updateCode"
            />
          </el-form-item>
          <el-form-item label="领域类别：" v-else>
            <dic-checkbox-group
              ref="checkboxProject"
              :code.sync="editTableObj.projectFieldType"
              tag="领域"
              key="领域"
              @updateCode="updateCode"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="saveItem">保 存</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50,100,200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Popover from '@/components/Popover.vue'
import CascaderArea from '@/components/CascaderArea.vue'
// import DataTime from '@/components/DataTime.vue'
import ImportData from '@/components/ImportData.vue'
import DicTagButton from '@/components/DicTagButton.vue'
import DicRadioButton from '@/components/DicRadioButton.vue'
import ButtonTable from '@/components/ButtonTable.vue'
import DataTime from "../../Product/com/dataTime.vue";
import { pagination } from '@/mixins/pagination'
import { getProjectList, deleteProjectById } from '@/api/numberProject'
import { exportData } from '@/utils/tool'
import DicCheckboxGroup from '@/components/DicCheckboxGroup.vue'
import { updateProjectData } from '@/api/numberProject'
export default {
  name: 'ProjectData',

  mixins: [pagination],

  components: {
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    DataTime,
    CascaderArea,
    Popover,
    DicCheckboxGroup,
  },

  data() {
    return {
      // 查询
      queryInfo: {
        columnName: 'change_time',
        pageSize: 10,
        pageNum: 1,
        queryConditions: '', // 搜索
        years: null, // 年
        month: null, // 月
        day: null, // 日
        projectCountry: '', // 国家
        projectProvince: '', // 省份
        projectCity: '', // 城市
        projectStage: '', // 项目阶段
        reportTags: [], // 行业类别
        reportTagsName: '', // 行业辅助标签
        fieldId: [], // 领域类别
        fieldIdName: '', // 领域辅助标签
        order: 1, // 0正序 1倒叙
        size: '',
      },
      projectTable: [], // 表格数据
      tags: [
        {
          name: '项目阶段',
          tagName: 'projectStage',
        },
        {
          name: '行业类别',
          tagName: 'reportTagsName',
          valLength: 0,
        },
        {
          name: '领域类别',
          tagName: 'fieldIdName',
          valLength: 0,
        },
      ],
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: '行业类别',
      dialogVisible: false,
      editTableObj: {
        projectIndustryType: [], // 行业
        industryChildrenId: [], // 领域类别二级标签
        projectFieldType: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
      },
      valueI: 0,
    }
  },
  created() {
    this.search()
  },

  methods: {
    fileClick() {
      this.search()
    },
    popoverClick(val) {
      if (val === 'all') {
        Object.assign(this.queryInfo, { id: [], size: '' })
      } else if (val == 0 && this.delete.multiId.length) {
        Object.assign(this.queryInfo, {
          id: this.delete.multiId,
        })
      } else {
        Object.assign(this.queryInfo, { size: val })
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        '/project/exportExcel', // 路径
        'application/msexcel', // 下载的文件类型
        this.search, // 下载完之后调查询方法
        '项目数据.xls'
      )
    },
    async search() {
      const res = await getProjectList(this.queryInfo)

      if (res.code === 200) {
        this.projectTable = res.data.list
        this.total = res.data.total
        this.projectTable.forEach((el) => {
          if (el.projectDataIntegrity) {
            el.projectDataIntegrity = Number(
              el.projectDataIntegrity.slice(0, -1)
            )
          } else {
            el.projectDataIntegrity = 0
          }
        })
      }

      this.total = res.data.total
    },
    searchCountry(val) {
      this.queryInfo.projectCountry = val
      this.search()
    },
    searchProvince(val) {
      this.queryInfo.projectProvince = val
      this.search()
    },
    searchCity(val) {
      if (val != '北京' && val != '天津' && val != '上海' && val != '重庆') {
        this.queryInfo.projectCity = val
        this.search()
      }
    },
    // 弹窗修改
    async saveItem() {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.projectFieldType =
          this.editTableObj.projectFieldType.concat(
            this.editTableObj.fieldChildrenId
          )
      }

      if (this.editTableObj.industryChildrenId) {
        this.editTableObj.projectIndustryType =
          this.editTableObj.projectIndustryType.concat(
            this.editTableObj.industryChildrenId
          )
      }

      this.editTableObj.projectIndustryType = [
        ...new Set(this.editTableObj.projectIndustryType),
      ]
      this.editTableObj.projectFieldType = [
        ...new Set(this.editTableObj.projectFieldType),
      ]

      const res = await updateProjectData(this.editTableObj)

      if (res.code === 200) {
        this.$message.success('编辑成功')
      }

      this.dialogVisible = false

      this.search()
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false

      this.$refs.checkboxProject.clear()
    },
    updateCode(val, tag) {
      switch (tag) {
        case '领域':
          this.editTableObj.fieldChildrenId = val
          break

        case '行业':
          this.editTableObj.industryChildrenId = val
          break

        default:
          break
      }
    },
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = 'create_time'
          this.queryInfo.order = 1
          this.valueI++
          break
        case 1:
          this.queryInfo.columnName = 'create_time'
          this.queryInfo.order = 0
          this.valueI++
          break
        case 2:
          this.queryInfo.columnName = 'change_time'
          this.queryInfo.order = 1
          this.valueI = 0
          break
      }
      this.search()
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },
    // 双击单元格
    tableDoubleClick(row, column) {
      if (column.label === '领域类别' || column.label === '行业类别') {
        Object.assign(this.editTableObj, row)

        this.editTableObj.projectIndustryType = this.formatString(
          this.editTableObj.projectIndustryType
        )

        this.editTableObj.projectFieldType = this.formatString(
          this.editTableObj.projectFieldType
        )

        if (column.label === '领域类别') {
          this.dialogType = '领域类别'
        } else if (column.label === '行业类别') {
          this.dialogType = '行业类别'
        }

        this.dialogVisible = true

        if (this.$refs.checkboxProject) {
          this.$refs.checkboxProject.getDicData()
        }
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return []
      } else if (!val.length) {
        return val
      } else {
        return val.split(',')
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = []

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === '行业类别' || name === '领域类别') {
          this.tags.push({
            name,
            tagName: closeTag,
            valLength: 0,
          })
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          })
        }
      }

      // 拿到name在tags的下标
      let result
      result = this.tags.findIndex((el) => {
        return el.name === name
      })

      switch (name) {
        case '行业类别':
          this.queryInfo.reportTags = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.reportTags =
              this.queryInfo.reportTags.concat(childrenVal)
          } else {
            this.tags[result].valLength = 0
          }

          break

        case '领域类别':
          this.queryInfo.fieldId = [val]

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length

            childrenVal = checkboxVal

            this.queryInfo.fieldId = this.queryInfo.fieldId.concat(childrenVal)
          } else {
            this.tags[result].valLength = 0
          }

          break

        default:
          break
      }

      this.search()
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1)

      switch (name) {
        case 'reportTagsName':
          this.queryInfo.reportTagsName = ''
          this.queryInfo.reportTags = []
          break
        case 'techIdName':
          this.queryInfo.techIdName = ''
          this.queryInfo.techId = []
          break
        default:
          this.queryInfo[name] = ''
          break
      }
      this.search()
    },
    formatTime(row, column) {
        const date = new Date(row[column.property])
        return date.getFullYear() + '-' +
         (date.getMonth()+1)+ '-' +
         date.getDate()
    },
    editItem(row) {
      let text = this.$router.resolve({
        path: "/numberProject/projectDataInfo",
        query: {
          projectDataType: 1,
          id: row.id,
        },
      });
      window.open(text.href, "_blank");
      // this.$router.push({
      //   name: 'ProjectDataInfo',
      //   query: {
      //     projectDataType: 1,
      //     id: row.id,
      //   },
      // })
    },

    addItem() {
      this.$router.push({
        name: 'ProjectDataInfo',
        query: {
          projectDataType: 0,
        },
      })
    },

    // 点击文字切换正倒序
    caretText() {
      this.queryInfo.order = !this.queryInfo.order

      if (this.queryInfo.order) {
        this.queryInfo.order = 1
      } else {
        this.queryInfo.order = 0
      }

      this.search()
    },
    // 正序
    caretTop() {
      this.queryInfo.order = 0

      this.search()
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 1

      this.search()
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map((item) => {
        return item.id
      })
    },
    _deleteProject(params) {
      this.$confirm('确认删除吗 ?', '提示')
        .then(async () => {
          const res = await deleteProjectById(params)

          if (res.code === 200) {
            this.$message.success('删除成功')
            this.search()
          }
        })
        .catch(() => {})
    },
    deleteItem(row) {
      this.delete.id.push(row.id)

      this._deleteProject(this.delete.id)
    },
    multiDelete() {
      if (this.delete.multiId.length) {
        this._deleteProject(this.delete.multiId)
      }
    },
    sortChange(column) {
      this.pageNum = 1
      if (column.prop === 'projectDataIntegrity') {
        this.queryInfo.columnName = 'project_data_integrity'
        if (column.order === 'ascending') {
          this.queryInfo.order = 0
        } else if (column.order === 'descending') {
          this.queryInfo.order = 1
        } else {
          this.queryInfo.order = 1
          this.queryInfo.columnName = 'change_time'
        }
      }
      this.search()
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === '领域类别' || column.label === '行业类别') {
        return 'cursor:pointer'
      }
    },
  },
}
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
